import { Component ,Renderer2,ElementRef,OnInit} from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-contador',
  templateUrl: './contador.component.html',
  styleUrls: ['./contador.component.scss']
})
export class ContadorComponent {  time!: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};
finishDate: Date = new Date(2024,8,21,10,30,0,0);


  eventDate: Date = new Date(2024, 8, 21); // Los meses en JavaScript comienzan desde 0 (enero), por lo que septiembre es 8



  constructor (private render:Renderer2, private el:ElementRef){

  }

  ngOnInit():void{

    this.time = {
      days: 0, hours: 0, minutes: 0, seconds: 0
    };
    this.start().subscribe();
    if(/iPhone|iPad|iPod/.test(navigator.userAgent)){
      const element = this.el.nativeElement.querySelector('.image2');
      //this.render.setStyle(element,'background-image',scroll);
     // this.render.removeStyle(element,'background-attachment')
    }
}

updateTime() {

  const now = new Date();
  const diff = this.finishDate.getTime() - now.getTime();

  // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const mins = Math.floor(diff / (1000 * 60));
  const secs = Math.floor(diff / 1000);

  // La diferencia que se asignará para mostrarlo en la pantalla
  this.time.days = days;
  this.time.hours = hours - days * 24;
  this.time.minutes = mins - hours * 60;
  this.time.seconds = secs - mins * 60;
}

start() {
  return interval(1000).pipe(
    map((x: number) => {
      this.updateTime();
      return x;
    })
  );
}
}
