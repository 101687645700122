<div class="container">
  <div class="row"  *ngIf="!true">
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
        data-aos="fade-right">
        <img src="assets/img/us/1.png" (click)="open(5)" class="img-fluid zoom" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
          data-aos="fade-right">
        <img src="assets/img/us/2.png" (click)="open(1)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
          data-aos="fade-left">
        <img src="assets/img/us/3.png" (click)="open(2)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
          data-aos="fade-left">
        <img src="assets/img/us/4.png" (click)="open(3)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
            data-aos="fade-right">
        <img src="assets/img/us/5.png" (click)="open(0)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
          data-aos="fade-right">
        <img src="assets/img/us/6.png" (click)="open(4)" class="img-fluid zoom" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
        data-aos="fade-left">
        <img src="assets/img/us/7.png" (click)="open(6)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 p-3"
          data-aos="fade-left">
        <img src="assets/img/us/8.png" (click)="open(7)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-5 col-md-4 col-sm-6 p-3"
          data-aos="fade-right">
        <img src="assets/img/us/9.png" (click)="open(9)" class="img-fluid zoom" />
      </div>
      <div class="col-lg-5 col-md-4 col-sm-6 p-3"
          data-aos="fade-left">
        <img src="assets/img/us/10.png" (click)="open(8)" class="img-fluid zoom" />
      </div>

    </div>

    <div class="row" >
      <div class="col-lg-12">
        <swiper-container class="mySwiper "  navigation="true" [breakpoints]="breakpoints" observer="true" observeParents="true">
          <swiper-slide *ngFor="let foto of _album let i = index; " class="col-md-4 pb-3 pe-3">
            <div class="image-container"
              style="color: #E0D6C1; ">
              <img
                [src]="foto.src"
                class="img-fluid zoom"
                (click)="open(foto.id)"
              />
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
</div>
