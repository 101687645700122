<div class="container">

  <div class="separator-30"></div>
  <div class="row">
    <div class="col-md-6 col-sm-12 p-3">
      <h1>Ceremonia</h1>
      <div class="card   text-center">
        <div class="card-body">
          <h5 class="card-title">Dia y hora</h5>
          <p class="card-text">Sábado 21 de Setiembre
            <br>
            10:00 A.M.
          </p>
          <div class="row">
            <div class="col-1 col-sm-3 col-md-2"></div>
            <div class="col-10 col-sm-6 col-md-8 d-grid gap-2">
              <a title="Agendar" class="addeventatc btn btn-primary"
                data-id="sf22214493"
                href="https://www.addevent.com/event/sf22214493"
                target="_blank">Agendar</a> <script type="text/javascript"
                src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js" async
                defer></script>
            </div>

            <div class="col-md-2"></div>

          </div>
          <div class="separator-30"></div>

          <hr class="my-5" />

          <h5 class="card-title">Lugar</h5>
          <p class="card-text">Auditorio
            <br>
            Oasis Grecia</p>
          <p class="card-text">1 Km este del Centro Educativo Santa Josefina</p>

          <div class="row">
            <div class="col-6 " style="text-align: right;">
              <a href="https://maps.app.goo.gl/Vnp9iVpLRU36ntFQ9"
                class="btn btn-primary btn-ubicacion" target="_blank">
                Ir con <i class="bi bi-google"></i>
              </a>
            </div>
            <div class="col-6" style="text-align: left;">
              <a href="https://waze.com/ul/hd1u13f72d"
                class="btn  btn-primary btn-ubicacion" target="_blank">
                Ir con <svg xmlns="http://www.w3.org/2000/svg" width="1.2em"
                  height="1.2em" viewBox="0 0 24 24"><path fill="white"
                    d="M13.218 0C9.915 0 6.835 1.49 4.723 4.148c-1.515 1.913-2.31 4.272-2.31 6.706v1.739c0 .894-.62 1.738-1.862 1.813c-.298.025-.547.224-.547.522c-.05.82.82 2.31 2.012 3.502c.82.844 1.788 1.515 2.832 2.036a3 3 0 0 0 2.955 3.528a2.966 2.966 0 0 0 2.931-2.385h2.509c.323 1.689 2.086 2.856 3.974 2.21c1.64-.546 2.36-2.409 1.763-3.924a13 13 0 0 0 1.838-1.465a10.73 10.73 0 0 0 3.18-7.65c0-2.882-1.118-5.589-3.155-7.625A10.9 10.9 0 0 0 13.218 0m0 1.217c2.558 0 4.967.994 6.78 2.807a9.53 9.53 0 0 1 2.807 6.78A9.53 9.53 0 0 1 20 17.585a9.65 9.65 0 0 1-6.78 2.807h-2.46a3.01 3.01 0 0 0-2.93-2.41a3.03 3.03 0 0 0-2.534 1.367v.024a9 9 0 0 1-2.41-1.788c-.844-.844-1.316-1.614-1.515-2.11a2.86 2.86 0 0 0 1.441-.846a2.96 2.96 0 0 0 .795-2.036v-1.789c0-2.11.696-4.197 2.012-5.861c1.863-2.385 4.62-3.726 7.6-3.726zm-2.41 5.986a1.19 1.19 0 0 0-1.191 1.192a1.19 1.19 0 0 0 1.192 1.193A1.19 1.19 0 0 0 12 8.395a1.19 1.19 0 0 0-1.192-1.192m7.204 0a1.19 1.19 0 0 0-1.192 1.192a1.19 1.19 0 0 0 1.192 1.193a1.19 1.19 0 0 0 1.192-1.193a1.19 1.19 0 0 0-1.192-1.192m-7.377 4.769a.596.596 0 0 0-.546.845a4.81 4.81 0 0 0 4.346 2.757a4.77 4.77 0 0 0 4.347-2.757a.596.596 0 0 0-.547-.845h-.025a.56.56 0 0 0-.521.348a3.59 3.59 0 0 1-3.254 2.061a3.59 3.59 0 0 1-3.254-2.061a.64.64 0 0 0-.546-.348" /></svg>
              </a>
            </div>
          </div>

          <div class="separator-30"></div>

          <hr class="my-5" />

          <div class="row">
            <div class="col-1 col-sm-3 col-md-2"></div>
            <div class="col-10 col-sm-6 col-md-8 d-grid gap-2">

              <div class="row">
                <h5 class="card-title">Notas</h5>
                <p>Información importante para tener en cuenta en la
                  ceremonia</p>
                <button class="btn btn-primary"
                  (click)="showFullScreenImage('assets/img/DetallesBoda.png')">Ver</button>

              </div>

            </div>
            <div class="row">
              <div class="col-1 col-sm-3 col-md-2"></div>
              <div class="col-10 col-sm-6 col-md-8 d-grid gap-2">

              </div>
              <div class="col-md-2"></div>

            </div>

            <div class="col-md-2"></div>

          </div>

          <div class="separator-30"></div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 p-3" *ngIf="invitadoFiesta">
      <h1>Estás invitado a la fiesta</h1>
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Hora</h5>
          <p class="card-text">04:00 P.M.</p>
          <div class="row">
            <div class="col-1 col-sm-3 col-md-2"></div>
            <div class="col-10 col-sm-6 col-md-8 d-grid gap-2">
              <a title="Agendar" class="addeventatc btn btn-primary"
                data-id="by22214985"
                href="https://www.addevent.com/event/by22214985"
                target="_blank">Agendar</a> <script type="text/javascript"
                src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js" async
                defer></script>
            </div>

            <div class="col-md-2"></div>

          </div>
          <div class="separator-30"></div>

          <hr class="my-5" />

          <h5 class="card-title">Lugar</h5>
          <p class="card-text">
            Centro de Retiros Peniel
            <br>
            (Quinta de macho)
          </p>
          <p class="card-text">100 mts Noreste de la ASADA de San Isidro
            <br>
            Ruta a Calle Rodriguez
          </p>
          <div class="row">
            <div class="col-6 " style="text-align: right;">
              <a href="https://maps.app.goo.gl/YMzzas4Na5J4XC8J7"
                class="btn btn-primary btn-ubicacion" target="_blank">
                Ir con <i class="bi bi-google"></i>
              </a>
            </div>
            <div class="col-6" style="text-align: left;">
              <a href="https://waze.com/ul/hd1u1d3sg2"
                class="btn  btn-primary btn-ubicacion" target="_blank">
                Ir con <svg xmlns="http://www.w3.org/2000/svg" width="1.2em"
                  height="1.2em" viewBox="0 0 24 24"><path fill="white"
                    d="M13.218 0C9.915 0 6.835 1.49 4.723 4.148c-1.515 1.913-2.31 4.272-2.31 6.706v1.739c0 .894-.62 1.738-1.862 1.813c-.298.025-.547.224-.547.522c-.05.82.82 2.31 2.012 3.502c.82.844 1.788 1.515 2.832 2.036a3 3 0 0 0 2.955 3.528a2.966 2.966 0 0 0 2.931-2.385h2.509c.323 1.689 2.086 2.856 3.974 2.21c1.64-.546 2.36-2.409 1.763-3.924a13 13 0 0 0 1.838-1.465a10.73 10.73 0 0 0 3.18-7.65c0-2.882-1.118-5.589-3.155-7.625A10.9 10.9 0 0 0 13.218 0m0 1.217c2.558 0 4.967.994 6.78 2.807a9.53 9.53 0 0 1 2.807 6.78A9.53 9.53 0 0 1 20 17.585a9.65 9.65 0 0 1-6.78 2.807h-2.46a3.01 3.01 0 0 0-2.93-2.41a3.03 3.03 0 0 0-2.534 1.367v.024a9 9 0 0 1-2.41-1.788c-.844-.844-1.316-1.614-1.515-2.11a2.86 2.86 0 0 0 1.441-.846a2.96 2.96 0 0 0 .795-2.036v-1.789c0-2.11.696-4.197 2.012-5.861c1.863-2.385 4.62-3.726 7.6-3.726zm-2.41 5.986a1.19 1.19 0 0 0-1.191 1.192a1.19 1.19 0 0 0 1.192 1.193A1.19 1.19 0 0 0 12 8.395a1.19 1.19 0 0 0-1.192-1.192m7.204 0a1.19 1.19 0 0 0-1.192 1.192a1.19 1.19 0 0 0 1.192 1.193a1.19 1.19 0 0 0 1.192-1.193a1.19 1.19 0 0 0-1.192-1.192m-7.377 4.769a.596.596 0 0 0-.546.845a4.81 4.81 0 0 0 4.346 2.757a4.77 4.77 0 0 0 4.347-2.757a.596.596 0 0 0-.547-.845h-.025a.56.56 0 0 0-.521.348a3.59 3.59 0 0 1-3.254 2.061a3.59 3.59 0 0 1-3.254-2.061a.64.64 0 0 0-.546-.348" /></svg>
              </a>
            </div>
          </div>

          <hr class="my-5" />
          <div class="row">

            <div class="row">
              <div class="col-1 col-sm-3 col-md-2"></div>
              <div class="col-10 col-sm-6 col-md-8 d-grid gap-2">
                <div class="row">
                  <h5 class="card-title">Notas </h5>
                  <p>Información importante para tener en cuenta en la
                    recepción</p>
                  <button href="#" class="btn btn-primary"
                    (click)="showFullScreenImage('assets/img/DetallesFiesta.png')">Ver</button>
                </div>
              </div>
              <div class="col-md-2"></div>

            </div>
            <div class="col-md-2"></div>

          </div>
          <div class="separator-30"></div>

        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <app-full-screen-image #fullScreenImage></app-full-screen-image>
  </div>
  <div class="separator-100"></div>

  <div class="row">
    <p class="text" *ngIf="codInvitacionExists">
      Hemos preparado una invitación para usted, sin embargo es indispensable
      que por favor confirme su asistencia antes del 19 de Agosto
    </p>
    <p class="text" *ngIf="!codInvitacionExists">
      Estamos muy emocionados de que nos quieras acompañar, solo necesitamos que
      por favor llenes el siguiente formulario antes del 19 de Agosto
    </p>
    <!-- <button type="button" class="btn btn-outline-primary btn-lg"
      (click)="navigateToConfirmar()">
      Confirmar Asistencia
    </button> -->
    <button type="button" class="btn btn-primary btn-lg btn-xl" data-bs-toggle="modal"
      data-bs-target="#modalInvitacion">
      Confirmar Asistencia
    </button>
  </div>

  <div>

  </div>

  <!-- Modal -->
<div class="modal fade modal-lg" id="modalInvitacion" tabindex="-1" aria-
labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title " id="exampleModalLabel">Será un gusto que nos puedas acompañar</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal"
     aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <app-confirmar></app-confirmar>
    </div>
    <div class="modal-footer">

    </div>
  </div>
</div>
</div>

</div>
