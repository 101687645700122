import { Component } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent {

  _album: Array<any> = [];
  constructor(private _lightbox: Lightbox) {

    this._album.push({
      src: 'assets/img/us/US1.JPG',
      caption: "txt",
      thumb: 'assets/img/us/US1.JPG'
   });

   this._album.push({
    src: 'assets/img/us/US2.JPG',
    caption: "txt",
    thumb: 'assets/img/us/US2.JPG'
 });

 this._album.push({
  src: 'assets/img//us/US3.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US3.JPG'
});

this._album.push({
  src: 'assets/img/us/US4.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US4.JPG'
});
this._album.push({
  src: 'assets/img/us/US5.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US5.JPG'
});
   
this._album.push({
  src: 'assets/img/us/US6.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US6.JPG'
});
this._album.push({
  src: 'assets/img/us/US7.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US7.JPG'
});
this._album.push({
  src: 'assets/img/us/US8.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US8.JPG'
});
this._album.push({
  src: 'assets/img/us/US9.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US9.JPG'
});
this._album.push({
  src: 'assets/img/us/US10.JPG',
  caption: "txt",
  thumb: 'assets/img/us/US10.JPG'
});
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
