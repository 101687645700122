import { Component } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-galeria-content',
  templateUrl: './galeria-content.component.html',
  styleUrls: ['./galeria-content.component.scss']
})
export class GaleriaContentComponent {
  isHandset?: boolean;
  breakpoints = {
    1: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    }
  };

  _album: Array<any> = [];
  constructor(private _lightbox: Lightbox, private breakpointObserver: BreakpointObserver) {

    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });


    this._album.push({
      src: 'assets/img/us/1.png',
      id: 0
   });

   this._album.push({
    src: 'assets/img/us/2.png',
    id: 1
 });

 this._album.push({
  src: 'assets/img/us/3.png',
  id: 2
});

this._album.push({
  src: 'assets/img/us/4.png',
  id: 3
});
this._album.push({
  src: 'assets/img/us/5.png',
  id: 4
});

this._album.push({
  src: 'assets/img/us/6.png',
  id: 5
});
this._album.push({
  src: 'assets/img/us/7.png',
  id: 6
});
this._album.push({
  src: 'assets/img/us/8.png',
  id: 7
});
this._album.push({
  src: 'assets/img/us/9.png',
  id: 8
});
this._album.push({
  src: 'assets/img/us/10.png',
  id: 9
});
this._album.push({
  src: 'assets/img/us/11.png',
  id: 10
});
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


}
