<section id="galeria" class="galeria">
  <div class="container" data-aos="fade-up">
    <div class="row">
      <div class="section-title">
        <h2>Galería</h2>
      </div>
    </div>
    <!-- <div class="row">
      <div
        *ngFor="let image of _album; let i = index"
        class="col-lg-3 col-md-4 col-sm-6 p-3"
      >
        <img [src]="image.thumb" (click)="open(i)" class="img-fluid zoom" />
      </div>
    </div> -->
  </div>

  <div class="row mb-5"></div>

  <div class="separator-100"></div>
</section>
