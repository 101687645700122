import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Invitacion } from '../models/invitacion.model';
import { Respuesta } from '../models/respuesta.model';
import { Invitado } from '../models/invitado.model';
import { environment } from 'src/environments/environment';
import { Solicitud } from '../models/solicitud.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl= environment.apiUrl;
  constructor(private _httpClient:HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  public getInvitacion(codigo:string):Observable<Respuesta<Invitacion>>{

    return this._httpClient.get<Respuesta<Invitacion>>(this.baseUrl+'/public/invitacion/'+codigo);
  }

  public updateInvitacion(codigo:string,data:any){
    return this._httpClient.put(this.baseUrl+'/public/invitacion/'+codigo, JSON.stringify(data),this.httpOptions);
  }

  public actualizarInvitadoCeremonia(invitado :Invitado){
    return this._httpClient.put(this.baseUrl+'/public/invitadoCeremonia', JSON.stringify(invitado),this.httpOptions);
  }
  public actualizarInvitadoRecepcion(invitado :Invitado){
    return this._httpClient.put(this.baseUrl+'/public/invitadoRecepcion', JSON.stringify(invitado),this.httpOptions);
  }

  public createSolicitud(solicitud:Solicitud): Observable<Respuesta<Solicitud>> {
  //  alert("La solicitud está por ser enviada")
    console.log("Solicitud:", JSON.stringify(solicitud));
    console.log("Enviando a URL:", this.baseUrl + '/public/solicitud');
    return this._httpClient.put<Respuesta<Solicitud>>(this.baseUrl + '/public/solicitud', JSON.stringify(solicitud), this.httpOptions).pipe(
      catchError(error => {
        console.error("Error en la solicitud:", error);
      //  alert("Error en la solicitud: " + JSON.stringify(error));
        return throwError(error);
      })
    );
  //  return this._httpClient.put<Respuesta<Solicitud>>(this.baseUrl+'/public/solicitud', JSON.stringify(solicitud),this.httpOptions);
  }


}
