import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LightboxModule } from 'ngx-lightbox';
import { ScrollService } from './scroll.service';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { CompromisoComponent } from './compromiso/compromiso.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { AcompanantesComponent } from './acompanantes/acompanantes.component';
import { ContadorComponent } from './contador/contador.component';
import { EventoComponent } from './evento/evento.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swiper from 'swiper';
import { ContactComponent } from './contact/contact.component';
import { AboutContentComponent } from './about/about-content/about-content.component';
import { HttpClientModule } from '@angular/common/http';
import { AcompanantesContentComponent } from './acompanantes/acompanantes-content/acompanantes-content.component';
import { GaleriaContentComponent } from './galeria/galeria-content/galeria-content.component';
import { ParallaxDirective } from './parallax.directive';
import { NgxParallaxModule } from '@yoozly/ngx-parallax';
import { CompromisoContentComponent } from './compromiso/compromiso-content/compromiso-content.component';
import { EventoContentComponent } from './evento/evento-content/evento-content.component';
import { ConfirmarComponent } from './confirmar/confirmar.component';
import { PaginaPrincipalComponent } from './pagina-principal/pagina-principal.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeContentComponent } from './home/home-content/home-content.component';
import { FullScreenImageComponent } from './full-screen-image/full-screen-image.component';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    CompromisoComponent,
    GaleriaComponent,
    AcompanantesComponent,
    ContadorComponent,
    EventoComponent,
    FooterComponent,
    ContactComponent,
    AboutContentComponent,
    AcompanantesContentComponent,
    GaleriaContentComponent,
    ParallaxDirective,
    CompromisoContentComponent,
    EventoContentComponent,
    ConfirmarComponent,
    PaginaPrincipalComponent,
    HomeContentComponent,
    FullScreenImageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LightboxModule,
    NgbModule,
    HttpClientModule,
    NgxParallaxModule,
    LayoutModule,
    ReactiveFormsModule
  ],
  providers: [ScrollService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
