import { Respuesta } from './../models/respuesta.model';
import { Solicitud } from './../models/solicitud.model';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Invitacion } from '../models/invitacion.model';
import { ApiService } from '../services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FullScreenImageComponent } from '../full-screen-image/full-screen-image.component';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})
export class ConfirmarComponent implements OnInit {
  miFormulario: FormGroup = new FormGroup({});
  @ViewChild('fullScreenImage') fullScreenImage!: FullScreenImageComponent;

  codInvitacion?: string;
  //mensajeConfirmacion?:string;
  mensajeSiVa:string="Nos complace saber que nos vas a acompañar";
  mensajeNoVa:string="Es una lastima que no puedas ir, si cambias de opinión no dudes en confirmar";
  errorMessage:string="";
  hayError:boolean=false;
  enviado:boolean=false;
  mensajeExito:string="";
  isLoading = false;


  alertas: { [id: string]: boolean } = {};
  mensajeConfirmacion: { [id: number]: string } = {};

  ngOnInit() {
    this.miFormulario = new FormGroup({
      nombreCompleto: new FormControl('', Validators.required),
      telefono: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      cantidad: new FormControl('1', Validators.required),
      comentarios: new FormControl('')
    });
  }

  invitacion: Invitacion = {
    codigo: '',
    recepcion: 0,
    invitados: [],
    existeInvitacion: false
  }; // Initialize with default values
  recepcion: boolean = false;
  constructor(private route: ActivatedRoute, private apiService: ApiService){
    this.route.queryParams.subscribe(params => {
      this.codInvitacion = params['codInvitacion'];
      const codInvitacion = params['codInvitacion'];
      if(codInvitacion!=undefined){
        this.apiService.getInvitacion(codInvitacion).subscribe(response => {
          this.invitacion = response.objetoRespuesta;
          this.recepcion=response.objetoRespuesta.recepcion==1?true:false;
        });
      }
    });


  }


  actualizarAsistencia(index: number, eventoAsiste:string, valor: boolean) {
  //  let checkbox = event.target as HTMLInputElement;
    if(eventoAsiste=="recepcion"){
      this.invitacion.invitados[index].vaRecepcion = valor;
      this.apiService.actualizarInvitadoRecepcion(this.invitacion.invitados[index]).subscribe();
    }else if(eventoAsiste=="ceremonia"){
      this.invitacion.invitados[index].vaCeremonia = valor;
      this.apiService.actualizarInvitadoCeremonia(this.invitacion.invitados[index]).subscribe();
    }

    if(valor){
     // this.mensajeConfirmacion = this.mensajeSiVa;

    }else{
     // this.mensajeConfirmacion = this.mensajeNoVa;
    }

    this.mensajeConfirmacion[this.invitacion.invitados[index].idInvitado] = valor ? this.mensajeSiVa : this.mensajeNoVa;
    this.mostrarAlerta(this.invitacion.invitados[index].idInvitado);
  }

  enviarSolicitud(){



  }
  onSubmit() {
  //  alert("Enviando")
    this.isLoading = true;
    if (this.miFormulario.valid) {
      // Lógica para enviar los datos al servidor
      const solicitud: Solicitud = {
        nombre: this.miFormulario.get('nombreCompleto')!.value,
        telefono: this.miFormulario.get('telefono')!.value,
        cantidad: this.miFormulario.get('cantidad')!.value,
        comentarios: this.miFormulario.get('comentarios')!.value
      };
   //   alert(JSON.stringify(solicitud))
      this.apiService.createSolicitud(solicitud).subscribe({
        next: (respuesta: Respuesta<Solicitud>) => {
          // Maneja la respuesta aquí
          console.log("Respuesta del servidor:", respuesta);
       //   alert(respuesta.error)
          if(!respuesta.error){
            this.enviado=true;
            this.hayError=false;
            this.errorMessage="";
            this.mensajeExito="La información se envío de forma correcta, muchas gracias"
          }else{
            this.hayError=true;
            this.errorMessage="Lo siento tuvimos problemas para guardar la información, por favor intentelo de nuevo"
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.error("Error en la solicitud:", error);
         // alert(JSON.stringify(error))

          // Maneja el error aquí
        }
      });
    }
  }

  mostrarError(mensaje: string) {
    this.hayError=true;
    this.errorMessage = mensaje;
  }

  mostrarAlerta(id: number) {
    this.alertas[id] = true;
  }

  cerrarAlerta(id: number) {
    this.alertas[id] = false;
  }

  showFullScreenImage(url:string, event: MouseEvent) {
    event.preventDefault(); // Previene la recarga de la página
    this.fullScreenImage.show(url);
  }
}
