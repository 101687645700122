<div class="container">
  <div class="row">
    <div class="section-title">
      <h2>Acompañantes</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 section-title" data-aos="fade-right">
      <h3>Damas</h3>
    </div>
    <div class="col-lg-12">
      <swiper-container class="mySwiper "  navigation="true" [breakpoints]="breakpoints" observer="true" observeParents="true">
        <swiper-slide *ngFor="let acompanante of acompanantesMujeres let i = index; " class="col-md-4 pb-3 pe-3">
          <div class="image-container" 
          [ngClass]="i % 2 !== 0 ? 'imagen-fondo2' : 'imagen-fondo1'"
          style="color: #E0D6C1; ">
            <img
              [src]="acompanante.imagen"
              class="img-fluid rounded-start rotate-image"
              alt="..."
            />
            <div class="image-text" style="padding-left: 10px;padding-right: 10px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>

          </div>
        </swiper-slide>
      </swiper-container>
    </div>

    <div class="col-lg-10"></div>
  </div>

  <div class="row">
    <div class="col-lg-12 section-title" data-aos="fade-left">
      <h3>Caballeros</h3>
    </div>
    <div class="col-lg-12">
      <swiper-container class="mySwiper "  navigation="true" [breakpoints]="breakpoints" observer="true" observeParents="true">
        <swiper-slide *ngFor="let acompanante of acompanantesHombres let i = index; " class="col-md-4 pb-3 pe-3">
          <div class="image-container" 
          [ngClass]="i % 2 !== 0 ? 'imagen-fondo1' : 'imagen-fondo2'"
          style="color: #E0D6C1; ">
            <img
              [src]="acompanante.imagen"
              class="img-fluid rounded-start rotate-image"
              alt="..."
            />
            <div class="image-text" style="padding-left: 10px;padding-right: 10px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>

          </div>
        </swiper-slide>
      </swiper-container>
    </div>

    <div class="col-lg-10"></div>
  </div>
</div>
