
<section id="home" class="d-flex flex-column justify-content-center"
  data-aos="zoom-in" data-aos-duration="3000">
  <div class>
    <div class="foto-portada d-block d-md-none" id="for-movil">
      <img src="../../assets/img/us9;16-2.jpg" class="img-fluid"
        alt="Foto de Portada">
    </div>

    <div class="foto-portada d-none d-md-flex" id="for-web">
      <img src="../../assets/img/us16;9jpg-4.jpg"
        class="img-fluid presentation-image" alt="Foto de Portada">

        <div class="centered-text">

          <span class="fechaweb save-the-date">
            Save The Date
          </span>
          <span class="fechaweb">
            21.09.2024
          </span>
        </div>

    </div>



  </div>
</section>
