<section id="contador">
  <div class="container">
    <div class="section-title">
      <h1 >Faltan</h1>

    </div>

    <div class=" container d-flex justify-content-center align-items-center" >
      
      <span class="fecha">
          <div class="timer-box">
            <div class="timer-item ">
              <span class="timer-value" id="days">
                {{ time.days }} 
                
              </span>
              <div class="timer-label">Días</div>
            </div>

            <div class="timer-item ">
              <span class="timer-value" id="hours">
                {{ time.hours }} 
              </span>
              <div class="timer-label">Horas</div>
            </div>

            <div class="timer-item ">
              <span class="timer-value" id="minutes">
                {{ time.minutes }} 
                
              </span>
              <div class="timer-label">Min</div>
            </div>

            <div class="timer-item ">
              <span class="timer-value" id="seconds">
                {{ time.seconds }}
                
              </span>
              <div class="timer-label">Segs</div>
            </div>
          </div>
      </span>
    </div>
  </div>
</section>
