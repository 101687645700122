import { Component,OnInit} from '@angular/core';



@Component({
  selector: 'app-acompanantes',
  templateUrl: './acompanantes.component.html',
  styleUrls: ['./acompanantes.component.scss']
})
export class AcompanantesComponent {


}
