<main id="main">
  <section class="d-flex flex-column justify-content-center">
    <div class="container">
      <div>

      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12" *ngFor="let invitado of invitacion.invitados; let i = index">
            <div class="card">
              <div class="">
                <h5 class="card-title">{{invitado.nombre}} {{invitado.apellidos}} </h5>

                <div class="container">
                  <div class="row align-items-center mb-2">
                    <div class="col-6 col-sm-6">
                      <label class="form-check-label" for="ceremoniaSwitchSi{{i}}">Vas a ir a la ceremonia?</label>
                    </div>
                    <div class="col-3 col-sm-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="radio" role="switch" name="recepcionRadio{{invitado.idInvitado}}" id="ceremoniaSwitchSi{{invitado.idInvitado}}"
                          (change)="actualizarAsistencia(i,'ceremonia',true)" [checked]="invitado.vaCeremonia === true">
                        <label class="form-check-label" for="ceremoniaSwitchSi{{i}}">Si</label>
                      </div>
                    </div>
                    <div class="col-3 col-sm-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="radio" role="switch" name="recepcionRadio{{invitado.idInvitado}}" id="ceremoniaSwitchNo{{invitado.idInvitado}}"
                          (change)="actualizarAsistencia(i,'ceremonia',false)" [checked]="invitado.vaCeremonia === false">
                        <label class="form-check-label" for="ceremoniaSwitchNo{{i}}">No</label>
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center mb-2" *ngIf="recepcion">
                    <div class="col-6 col-sm-6">
                      <label class="form-check-label" for="recepcionSwitchSi{{i}}">Vas a ir a la recepción?</label>
                    </div>
                    <div class="col-3 col-sm-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="radio" role="switch" name="ceremoniaRadio{{invitado.idInvitado}}" id="recepcionSwitchSi{{invitado.idInvitado}}"
                          (change)="actualizarAsistencia(i,'recepcion',true)" [checked]="invitado.vaRecepcion === true">
                        <label class="form-check-label" for="recepcionSwitchSi{{i}}">Si</label>
                      </div>
                    </div>
                    <div class="col-3 col-sm-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="radio" role="switch" name="ceremoniaRadio{{invitado.idInvitado}}" id="recepcionSwitchNo{{invitado.idInvitado}}"
                          (change)="actualizarAsistencia(i,'recepcion',false)" [checked]="invitado.vaRecepcion === false">
                        <label class="form-check-label" for="recepcionSwitchNo{{i}}">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="alert alert-success alert-dismissible fade show" [id]="'alert' + invitado.idInvitado" role="alert" *ngIf="alertas[invitado.idInvitado]">
                  Asistencia confirmada. {{mensajeConfirmacion[invitado.idInvitado]}}.
                  <button type="button" class="btn-close" (click)="cerrarAlerta(invitado.idInvitado)" aria-label="Close"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row " *ngIf="invitacion.existeInvitacion">
          <div class="col-md-6 col-12">
            <a href="#" class=""
            (click)="showFullScreenImage('assets/img/DetallesBoda.png', $event)">Ver notas importantes de la ceremonia</a>
          </div>

          <div class="col-md-6 col-12"  *ngIf="recepcion">
            <a href="#" class=""
            (click)="showFullScreenImage('assets/img/DetallesFiesta.png', $event)">Ver notas importantes de la recepción</a>
          </div>
        </div>
        <div class="row">
          <app-full-screen-image #fullScreenImage></app-full-screen-image>
        </div>
      </div>


      <div class="container" *ngIf="recepcion">
        <div class="separator-100"></div>
      </div>
      <div class="container" *ngIf="!invitacion.existeInvitacion">
        <div class="row">
          <p>
            Estamos muy emocionados de que nos quieras acompañar, por favor llena el siguiente formulario y estaremos buscando un espacio para usted.
          </p>


        </div>
        <div class="row">
          <form [formGroup]="miFormulario" (ngSubmit)="onSubmit()" >
            <div class="mb-3">
              <label for="nombreCompleto" class="form-label">Nombre
                Completo</label>
              <input type="text" class="form-control" id="nombreCompleto"
                placeholder="nombre completo" formControlName="nombreCompleto">
            </div>
            <div class="mb-3">
              <label for="telefono" class="form-label">Telefono</label>
              <input type="number" class="form-control" id="telefono" formControlName="telefono"
                placeholder="Telefono">
            </div>
            <div class="mb-3">
              <label for="cantidad" class="form-label">Cantidad de personas</label>
              <select class="form-select" aria-label="Default select example" formControlName="cantidad"
                id="cantidad">
                <option selected value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="comentarios" class="form-label">Comentarios</label>
              <textarea class="form-control" id="comentarios" formControlName="comentarios"
                rows="3"></textarea>
            </div>
            <div class="row" *ngIf="enviado">
              <div class="alert alert-success" role="alert">
                {{ mensajeExito }}
              </div>

            </div>
            <div class="row" *ngIf="hayError">
              <div class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>

            </div>

            <div class="row">

            </div>
            <div class="row ">
              <div class="col-md-6 col-12">
                <a href="#" class=""
                (click)="showFullScreenImage('assets/img/DetallesBoda.png', $event)">Ver notas importantes de la ceremonia</a>
              </div>

              <div class="col-md-6 col-12"  *ngIf="recepcion">
                <a href="#" class=""
                (click)="showFullScreenImage('assets/img/DetallesFiesta.png', $event)">Ver notas importantes de la recepción</a>
              </div>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="miFormulario.invalid || enviado || isLoading">
              <ng-container *ngIf="!isLoading; else loading">
                Enviar
              </ng-container>
              <ng-template #loading>
                <i class="bi bi-hourglass-top"></i> Enviando...
              </ng-template>

            </button>
          </form>



        </div>
      </div>


    </div>
  </section>
</main>
