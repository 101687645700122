import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-acompanantes-content',
  templateUrl: './acompanantes-content.component.html',
  styleUrls: ['./acompanantes-content.component.scss']
})
export class AcompanantesContentComponent {
  acompanantesHombres: any[] = [];
  acompanantesMujeres: any[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('assets/acompanantesHombres.json').subscribe(data => {
      this.acompanantesHombres = data as any[];
    });
    this.http.get('assets/acompanantesMujeres.json').subscribe(data => {
      this.acompanantesMujeres = data as any[];
    });
  }
  breakpoints = {
    1: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    }
  };
}
