<!-- ======= Mobile nav toggle button ======= -->
   <button type="button" class="mobile-nav-toggle d-xl-none" (click)="toggleMobileNav()">
    <i class="bi mobile-nav-toggle" [class.bi-list]="!mobileNavOpen" [class.bi-x]="mobileNavOpen">
    </i>
  </button>
  <!--<i class="bi bi-list mobile-nav-toggle d-lg-none" (click)="isMenuCollapsed = !isMenuCollapsed"></i> -->
  <!-- ======= Header ======= -->
  <header id="header" class="d-flex flex-column justify-content-center" [class.header-open]="mobileNavOpen">
    <nav id="navbar" class="navbar nav-menu d-lg-block" >
      <ul>
        <li>
          <a [href]="(codInvitacion ? '?codInvitacion=' + codInvitacion : '')+'#home'" class="nav-link active" [class.active]="activeSection === 'home'" (click)="toggleMobileNav()">
            <i class="bx bx-home"></i> <span>Home</span>
          </a>
        </li>
        <li>
          <a [href]="(codInvitacion ? '?codInvitacion=' + codInvitacion : '')+'#about'" class="nav-link" [class.active]="activeSection === 'about'" (click)="toggleMobileNav()">
            <i class='bx bx-heart'></i> <span>Nosotros</span>
          </a>
        </li>
        <li>
          <a [href]="(codInvitacion ? '?codInvitacion=' + codInvitacion : '')+'#compromiso'" class="nav-link" [class.active]="activeSection === 'compromiso'" (click)="toggleMobileNav()">
            <i class='bx bx-diamond'></i> <span>Compromiso</span>
          </a>
        </li>
        <li>
          <a [href]="(codInvitacion ? '?codInvitacion=' + codInvitacion : '')+'#evento'" class="nav-link" [class.active]="activeSection === 'evento'" (click)="toggleMobileNav()">
            <i class='bx bx-church'></i> <span>El evento</span>
          </a>
        </li>
        <li>
          <a [href]="(codInvitacion ? '?codInvitacion=' + codInvitacion : '')+'#galeria'" class="nav-link" [class.active]="activeSection === 'galeria'" (click)="toggleMobileNav()">
            <i class='bx bx-photo-album'></i> <span>Galeria</span>
          </a>
        </li>


      </ul>
    </nav><!-- .nav-menu -->
<!-- Menú para dispositivos móviles -->

  </header><!-- End Header -->

  <!-- ======= home Section ======= -->
