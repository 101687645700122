import { Component } from '@angular/core';

@Component({
  selector: 'app-compromiso',
  templateUrl: './compromiso.component.html',
  styleUrls: ['./compromiso.component.scss']
})
export class CompromisoComponent {

}
