import { Component,HostListener,OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit{
  constructor(){

  }
  ngOnInit():void{
    /* let text = document.getElementById('textparallex');
    window.addEventListener('scroll',function(){
      let value = window.scrollY;
      text!.style.top = value * 0.5 + 'px';
    }
  ) */
}
/* 
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const parallax = document.getElementById('seeyou');
    let scrollPosition = window.pageYOffset;
    if (parallax) {
      parallax.style.backgroundPosition = 'center ' + (scrollPosition * 0.5) + 'px';
    } 
  }*/
}
