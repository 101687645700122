import { Component,HostListener,OnInit,ElementRef,Renderer2 } from '@angular/core';

@Component({
  selector: 'app-about-content',
  templateUrl: './about-content.component.html',
  styleUrls: ['./about-content.component.scss']
})
export class AboutContentComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.setAOS();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setAOS();
  }

  setAOS() {
    const element = this.el.nativeElement.querySelector('.animatable');
    if (window.innerWidth <= 768) {
      this.renderer.setAttribute(element, 'data-aos', 'fade-right');
    } else {
      this.renderer.setAttribute(element, 'data-aos', 'fade-left');
    }
  }
}
