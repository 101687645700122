<div class="container">
  <div class="row">
    <div class="col-lg-6" data-aos="fade-right">
      <div class="card " >
        <div class="separator-30"></div>

        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Hilary Madrigal Valverde</h5>
              <p class="card-text">
                Aventurera, amante del océano, exploradora incansable y obviamente saprissista de corazón,
                disfruto tanto bucear en las profundares del mar, saltar a 9000 pies de altura, relajarme
                en las playas doradas del caribe o gritar a todo pulmón en el estadio.
                Un poco geek y amante de aprender cosas nuevas. Encuentro plenitud en mi servicio en la iglesia,
                donde por más de 15 años he dedicado tiempo y energía a servir a los demás con amor y compasión.
              </p>
              <p class="card-text text-center">
                <a href="https://www.facebook.com/hilarymv" target="_blank">
                  <i class="bx bxl-facebook"></i>
                </a>
                <a href="https://www.instagram.com/hila3010" target="_blank">
                  <i class="bx bxl-instagram bx-rotate-90"></i>
                </a>
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <img
              src="assets/img/hila.jpg"
              class="img-fluid rounded-circle"
              alt="..."
            />
          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-6 animatable" >
      <div class="card mb-3" >
        <div class="separator-30"></div>

        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="assets/img/jo.jpg"
              class="img-fluid rounded-circle float-end"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Josue Rivas Muñoz</h5>
              <p class="card-text">
                Apasionado de la informática y las motos, disfruto de la libertad en cada curva del camino.
                Amante del vino y las parrilladas, encuentro en ellas el escenario perfecto para compartir momentos especiales con familia y amigos.
                Me gusta la adrenalina, me encanta sumergirme en las profundidades del océano y pasar tiempo con mi amor explorando el mundo juntos.
                Siempre hay tiempo para una buena canción, se aceptan recomendaciones.
              </p>
              <p class="card-text text-center">
                <a href="https://www.facebook.com/josrv089" target="_blank">
                  <i class="bx bxl-facebook"></i>
                </a>
                <a href="https://www.instagram.com/josrv089" target="_blank">
                  <i class="bx bxl-instagram bx-rotate-90"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
