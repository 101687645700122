<div class="container" data-aos="fade-up">
  <div class="separator-30"></div>

  <div class="row">
    <div class="col-lg-5 pt-3 pb-3">
      <div class="image-container" data-aos="fade-right">
        <div class="image-shadow"></div>
        <img src="../../assets/img/marco1.png" class="img-fluid img">
      </div>
    </div>
    <!-- <div class="col-lg-7 pt-3 pb-3  d-none d-lg-block"> -->
      <div class="col-lg-7 pt-3 pb-3 " *ngIf="!isHandset" data-aos="fade-left">
      <p>Hace más de una década, nuestras vidas se cruzaron en los pasillos de la Universidad, y aunque compartimos dolores de cabeza programando, el dulce con amargo del ser líderes de jóvenes y salidas de esas que construyen nuevas amistades, nuestros caminos aún no estaban listos para unirse.</p>
      <p>En 2022 un dron y una moto se topan de la forma más desinteresada posible, llevándonos a nuevas conversaciones llenas de risas, vulnerabilidades, unas cuantas aventuras extremas y sentimientos de esos que te provocan mariposas, pero sobretodo paz, como esa que sientes cuando llegas al lugar que llamas “hogar”.</p>
      <p>El 31 de Diciembre, después de recorrer el país de oeste a este, habiendo disfrutado del amanecer en el caribe, llegamos al atardecer del pacífico, y a 600 metros de altura sobre el Océano y un cielo teñido de colores, iniciamos una nueva aventura, esa que tiene que ver con el “para siempre”. </p>
      <p>Amamos la vida y lo que Dios nos ofrece en ella, nos encanta sumergirnos bajo el mar hacia mundos que no conocíamos, la sensación del viento de una caída libre a 200km/h, de esas que te hacen sentir más vivo que nunca, conocer nuevos lugares y las conversaciones que se dan en medio, recorrer nuevos
            senderos y encontrar la plenitud en el servicio a los demás a través de nuestro amor y trabajo como Iglesia. Nuestra historia, aunque tiene varios capítulos ya, apenas inicia, y nos encanta la idea de que Dios sea quién sostenga el lápiz y haya decidido escribirnos juntos, y hacer la historia aún más emocionante añadiendo a gente como vos a nuestra vida. </p>
    </div>
    <div class="row" *ngIf="isHandset">

      <div *ngFor="let card of cards; let i = index" class="col-sm-12 p-3">
        <div class="card bg-dark text-white">
          <div class="card-img-wrapper">
            <img [ngClass]="{
              'rotate': isRotated[i],
              'card-img-custom2': isRotated[i] && i === 3
              }" src="{{card.imageUrl}}"

            class="card-img card-img-custom" alt="...">
            <div *ngIf="isRotated[i]" class="card-overlay">
              <p class="" (click)="toggleView(i)">
                {{card.fullText}}
                <button (click)="toggleView(i)" class="btn btn-secondary">Leer menos</button>
              </p>
            </div>
          </div>
          <div *ngIf="!isRotated[i]" class="card-img-overlay d-flex align-items-center justify-content-center">
            <h5 class="card-title">
              <p>{{card.summary}}</p>
              <button (click)="toggleView(i)" class="btn btn-primary">Leer más</button>
            </h5>
          </div>
        </div>
      </div>

  </div>


  </div>


</div>
