<div class="">
<div class="row">
  <img src="../../assets/img/goldWave.png" alt class="img-fluid separador">

  <div class="cover-text">
    <h1>Save The Date</h1>
  </div>

</div>

<div class>
  <div class=" ">
    <div>

    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <span class="fecha">
        21.09.2024
      </span>
    </div>
    <div class="row">
      <div class="logo-container">
        <img src="../../assets/img/LogoNdWaves.png" alt class="img-fluid">

      </div>
    </div>

  </div>

</div>

</div>
