import { Component,HostListener,AfterViewInit,OnInit } from '@angular/core';
import * as AOS from 'aos';
import { ScrollService } from './scroll.service'; // adjust the path as needed
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'myboda';
  isLoading = true;

  constructor(private scrollService: ScrollService) { }

  ngOnInit() {
    // Simula una espera de 2 segundos para ocultar el loader
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event : any) {
    const sectionHome = document.getElementById('home');
    const sectionAbout = document.getElementById('about');
    const sectionCompromiso = document.getElementById('compromiso');
    const sectionGaleria = document.getElementById('galeria');
    //const sectionAcompanantes = document.getElementById('acompanantes');
    const sectionEvento = document.getElementById('evento');


    // Add more elements for more sections
    if (this.isElementInView(sectionHome)) {
      this.scrollService.emitSection('home');
    } else if (this.isElementInView(sectionAbout)) {
      this.scrollService.emitSection('about');
    } else if (this.isElementInView(sectionCompromiso)) {
      this.scrollService.emitSection('compromiso');
    } else if (this.isElementInView(sectionGaleria)) {
      this.scrollService.emitSection('galeria');
    }
    /*else if (this.isElementInView(sectionAcompanantes)) {
      this.scrollService.emitSection('acompanantes');
    } */
    else if (this.isElementInView(sectionEvento)) {
      this.scrollService.emitSection('evento');
    }

    // Add more conditions for more sections
  }

  private isElementInView(element : any) {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  }
  ngAfterViewInit() {
    AOS.init({
      duration: 800, // Duration of the animation in milliseconds
      easing: 'ease', // Easing function for the animation
      once: false, // Whether to animate only once
      mirror: false, // Whether to animate on scroll up
    });
  }
}
