import { Component,OnInit } from '@angular/core';
import { ScrollService } from '../scroll.service'; // adjust the path as needed
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit{
  public activeSection!: string;
  isMenuCollapsed = true;
  mobileNavOpen = false;
  codInvitacion? :string ;

  constructor(private scrollService: ScrollService, private route: ActivatedRoute) {
    console.log("Opa! Que hace revisando la consola?")
    this.route.queryParams.subscribe(params => {
      this.codInvitacion = params['codInvitacion'];
    });
  }
  ngOnInit() {
    this.scrollService.sectionInViewport.subscribe(sectionId => {
      this.activeSection = sectionId;
    });
  }

  toggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen;

  }
}
