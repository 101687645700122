

    <div *ngIf="isLoading" class="heart-loader">
      <i class="bi bi-heart-fill heart"></i>
    </div>
    <app-navbar></app-navbar>


    <!-- End #main -->


    <router-outlet></router-outlet>
    <!-- ======= Footer ======= -->
    <app-footer></app-footer>

