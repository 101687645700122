<main id="main">
    <div class="main-content">
    <app-home></app-home>
    <app-home-content></app-home-content>


    <div class="separator-100"></div>
      <app-contador></app-contador>


      <div class="separator-100"></div>
      <app-about></app-about>
        <app-about-content></app-about-content>

      <div class="separator-100"></div>

      <app-compromiso></app-compromiso>
        <app-compromiso-content></app-compromiso-content>

      <div class="separator-100"></div>

      <app-evento></app-evento>
        <app-evento-content></app-evento-content>

      <div class="separator-100"></div>

      <app-galeria></app-galeria>
        <app-galeria-content></app-galeria-content>

  </div>
    <!-- End Contact Section -->
  </main>
