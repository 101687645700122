import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-compromiso-content',
  templateUrl: './compromiso-content.component.html',
  styleUrls: ['./compromiso-content.component.scss']
})
export class CompromisoContentComponent {
  isHandset?: boolean;
  isExpanded = false;

  cards = [
    { imageUrl: '../../assets/img/fistmeet.png', summary: 'Hace más de una década...', fullText: 'Hace más de una década, nuestras vidas se cruzaron en los pasillos de la Universidad, y aunque compartimos dolores de cabeza programando, el dulce con amargo del ser líderes de jóvenes y salidas de esas que construyen nuevas amistades, nuestros caminos aún no estaban listos para unirse.' },
    { imageUrl: '../../../assets/img/moto.png', summary: 'En 2022 un dron y una moto se topan de la forma más desinteresada posible...', fullText: 'En 2022 un dron y una moto se topan de la forma más desinteresada posible, llevándonos a nuevas conversaciones llenas de risas, vulnerabilidades, unas cuantas aventuras extremas y sentimientos de esos que te provocan mariposas, pero sobretodo paz, como esa que sientes cuando llegas al lugar que llamas “hogar”.' },
    { imageUrl: '../../../assets/img/prop.png', summary: 'El 31 de Diciembre, después de recorrer el país de oeste a este...', fullText: 'El 31 de Diciembre, después de recorrer el país de oeste a este, habiendo disfrutado del amanecer en el caribe, llegamos al atardecer del pacífico, y a 600 metros de altura sobre el Océano y un cielo teñido de colores, iniciamos una nueva aventura, esa que tiene que ver con el “para siempre”.' },
    { imageUrl: '../../../assets/img/comprometidos.png', summary: 'Amamos la vida y lo que Dios nos ofrece en ella, nos encanta...', fullText: 'Amamos la vida y lo que Dios nos ofrece en ella, nos encanta sumergirnos bajo el mar hacia mundos que no conocíamos, la sensación del viento de una caída libre a 200km/h, de esas que te hacen sentir más vivo que nunca, conocer nuevos lugares y las conversaciones que se dan en medio, recorrer nuevos senderos y encontrar la plenitud en el servicio a los demás a través de nuestro amor y trabajo como Iglesia. Nuestra historia, aunque tiene varios capítulos ya, apenas inicia, y nos encanta la idea de que Dios sea quién sostenga el lápiz y haya decidido escribirnos juntos, y hacer la historia aún más emocionante añadiendo a gente como vos a nuestra vida.' }
    // Agrega más tarjetas según sea necesario
  ];

  isRotated = Array(this.cards.length).fill(false);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  toggleView(index: number) {
    this.isRotated[index] = !this.isRotated[index];
  }
}
