import { Component,Renderer2,ElementRef, ViewChild } from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';
import { map } from 'rxjs/internal/operators/map';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Respuesta } from '../../models/respuesta.model';
import { Invitacion } from '../../models/invitacion.model';
import { Router } from '@angular/router';
import { FullScreenImageComponent } from 'src/app/full-screen-image/full-screen-image.component';

@Component({
  selector: 'app-evento-content',
  templateUrl: './evento-content.component.html',
  styleUrls: ['./evento-content.component.scss']
})
export class EventoContentComponent {
  @ViewChild('fullScreenImage') fullScreenImage!: FullScreenImageComponent;

  time!: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  finishDate: Date = new Date(2024,8,21,10,30,0,0);
  codInvitacionExists: boolean;
  respuesta: Respuesta<Invitacion>;
  codInvitacion?: string;

  mapsUrl: string;
  wazeUrl: string;

  showIcons: boolean = false;
  positionX: number = 0;
  positionY: number = 0;
  invitadoFiesta: boolean=false;

  isVisible: boolean = false;

  constructor(private route: ActivatedRoute, private apiService: ApiService,private router: Router,private render:Renderer2, private el:ElementRef ) {
    this.codInvitacionExists = false;
    this.mapsUrl = `https://maps.app.goo.gl/Vnp9iVpLRU36ntFQ9`;
    this.wazeUrl = `https://maps.app.goo.gl/Vnp9iVpLRU36ntFQ9`;
    this.respuesta = {
      responseCode: "",
      error: "",
      message: "",
      objetoRespuesta: {} as Invitacion
    };
    this.route.queryParams.subscribe(params => {
      this.codInvitacion = params['codInvitacion'];
    });
  }
  ngOnInit(): void {
    if(/iPhone|iPad|iPod/.test(navigator.userAgent)){
      const element = this.el.nativeElement.querySelector('.image2');
      //this.render.setStyle(element,'background-image',scroll);
    //  this.render.removeStyle(element,'background-attachment')
    }
    // Inicializamos el momento que falta hasta llegar al tiempo objetivo con valores en 0
    this.time = {
      days: 0, hours: 0, minutes: 0, seconds: 0
    };
    this.start().subscribe();
    this.route.queryParams.subscribe(params => {
      const codInvitacion = params['codInvitacion'];

      if(codInvitacion!=undefined){
        this.apiService.getInvitacion(codInvitacion).subscribe(response => {
          this.respuesta = response;
          if(response.responseCode === "200"){
            this.codInvitacionExists = true;
            this.invitadoFiesta = response.objetoRespuesta.recepcion==1;
          }
        });
      }
    });

  }

  showFullScreenImage(url:string) {
    this.fullScreenImage.show(url);
  }

  updateTime() {

    const now = new Date();
    const diff = this.finishDate.getTime() - now.getTime();

    // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const mins = Math.floor(diff / (1000 * 60));
    const secs = Math.floor(diff / 1000);

    // La diferencia que se asignará para mostrarlo en la pantalla
    this.time.days = days;
    this.time.hours = hours - days * 24;
    this.time.minutes = mins - hours * 60;
    this.time.seconds = secs - mins * 60;
  }

  start() {
    return interval(1000).pipe(
      map((x: number) => {
        this.updateTime();
        return x;
      })
    );
  }

  navigateToConfirmar() {
    this.router.navigate(['/confirmar'], { queryParams: { codInvitacion: this.codInvitacion } });
  }

  toggleIcons() {
    this.showIcons = !this.showIcons;
  }


}
