import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-screen-image',
  templateUrl: './full-screen-image.component.html',
  styleUrls: ['./full-screen-image.component.scss']
})
export class FullScreenImageComponent {
  @Input() imageSrc: string = '';
  isVisible: boolean = false;

  show(imageSrc: string) {
    this.imageSrc = imageSrc;
    this.isVisible = true;
  }

  close(event: MouseEvent) {
    event.stopPropagation();
    this.isVisible = false;
  }
}
