import { Injectable,EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public sectionInViewport = new EventEmitter<string>();


  constructor() { }

  emitSection(sectionId: string) {
    this.sectionInViewport.emit(sectionId);
  }
}
