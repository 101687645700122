import { Component } from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';
import { map } from 'rxjs/internal/operators/map';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Respuesta } from '../models/respuesta.model';
import { Invitacion } from '../models/invitacion.model';


@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.scss']
})
export class EventoComponent {

  time!: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  finishDate: Date = new Date(2024,8,21,10,30,0,0);
  codInvitacionExists: boolean;
  respuesta: Respuesta<Invitacion>;
  constructor(private route: ActivatedRoute, private apiService: ApiService ) {
    this.codInvitacionExists = false;
    this.respuesta = {
      responseCode: "",
      error: "",
      message: "",
      objetoRespuesta: {} as Invitacion
    };
  }
  ngOnInit(): void {
    // Inicializamos el momento que falta hasta llegar al tiempo objetivo con valores en 0
    this.time = {
      days: 0, hours: 0, minutes: 0, seconds: 0
    };
    this.start().subscribe();
    this.route.queryParams.subscribe(params => {
      const codInvitacion = params['codInvitacion'];
      if(codInvitacion!=undefined){
        this.apiService.getInvitacion(codInvitacion).subscribe(response => {
          this.respuesta = response;
          if(response.responseCode === "200"){
            this.codInvitacionExists = true;
          }
        });
      }
    });

  }
  updateTime() {

    const now = new Date();
    const diff = this.finishDate.getTime() - now.getTime();

    // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const mins = Math.floor(diff / (1000 * 60));
    const secs = Math.floor(diff / 1000);

    // La diferencia que se asignará para mostrarlo en la pantalla
    this.time.days = days;
    this.time.hours = hours - days * 24;
    this.time.minutes = mins - hours * 60;
    this.time.seconds = secs - mins * 60;
  }

  start() {
    return interval(1000).pipe(
      map((x: number) => {
        this.updateTime();
        return x;
      })
    );
  }
}
